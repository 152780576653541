import logo from './logo.svg';
import './App.css';

import { Routes, Route } from 'react-router-dom';

import Menu from './Components/Menu/Menu';

import Home from './Pages/Home/Home';
import Services from './Pages/Services/Services';
import Blogs from './Pages/Blogs/Blogs'
import AboutUs from './Pages/About-Us/AboutUs'
import ContactUs from './Pages/Contact-Us/ContactUs';
import Login from './Pages/Login/Login';

import Footer from './Components/Footer/Footer'

function App() {
  return (
    <div className="App">
      <Menu/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/blogs' element={<Blogs/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/contact-us' element={<ContactUs/>}/>
        <Route path='/login' element={<Login/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
