import React from 'react'

import '../Contact-Us/ContactUsStyle.css'

const ContactUs = () => {
  return (
    <div className='contact-us'>
        <div className="welcome-banner"><p>Contact Us</p></div>
        <div className='main-contact-us-wrapper'>
            <div className="get-in-touch-wrapper">
                <div className="get-in-touch-box">
                    <div className="get-in-touch-main-box">
                        <div className="get-in-touch-box-head">
                            Get in Touch
                        </div>
                        <div className='get-in-touch-desc'>Feel free to get in touch with us</div>
                        <div className="contact-info">
                            contact@info.com <br /> <br />
                            555-346-7495 <br /> <br />
                            13 Fifth Avenue, New York, NY 101660 <br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-form-wrapper">
                <div className="contact-form-box">
                    <form action="" className='form-container'>
                        <label className='name-label'>Name*</label>
                        <input type="text" placeholder='First Name' className='first-name-input'/>
                        <input type="text" placeholder='Last Name' className='last-name-input'/>

                        <label className='email-label'>Email*</label>
                        <input type="text" placeholder='Email' className='email-input'/>

                        <label className='message-label'>Message*</label>
                        <textarea name="message" placeholder='Message' className="message-area" />

                        <button type="submit" className='send-button'>
                            SEND
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactUs