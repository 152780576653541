import React from 'react'

import { Link } from 'react-router-dom'

import '../Login/LoginStyle.css'

const Login = () => {
  return (
    <div className='login'>
        <div className='login-box-wrapper'>
            <div className="login-head">Login</div>
            <div className="login-form">
                <form action="">
                    <input type="text" placeholder='Enter your Email' className='email-input'/>
                    <input type="password" placeholder='Password' className='password-input'/>
                
                    <div className="term-condition">
                        <input type="checkbox"  />
                        <label>I Agree all the term & conditions</label>
                    </div>

                    <button className='login-input'>Login</button>
                </form>
            </div>
            <div className="login-other-option-wrapper">
                <Link to="/">
                    <div className="login-with-google login-option">
                        <p>Login with Google</p>
                    </div>
                </Link>

                <Link to="/">
                    <div className="login-with-phone-number login-option">
                        <p>Login with phone</p>
                    </div>
                </Link>
                <div className='sign-up'>
                    <p>Create new Account</p>
                    <Link to="/" className='signup-link'>Sign Up</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login