import React from 'react'

import '../About-Us/AboutUsStyle.css'

const AboutUs = () => {
  return (
    <div className='aboutUs'>
        <div className="about-us-welcome-banner">
            <div className="about-us-text">
                About Us
            </div>
        </div>
        <div className="about-algo-trading">
            <p className='algo-trading-content'>
                "Algorithmic trading strategies involves making trading decisions based on pre set rules that are programmed into a computer. <br />
                A trader or investor writes code that executes trade on behalf of the trader or investor when certain condition are met."
            </p>
        </div>

        <div className="pros-wrapper">
            <div className="pros-wrapper-title">
                What you able to do.
            </div>
            <div className="pro-list-wrapper">
                <li className='pros-point'>
                    <div className='pros-point-content'>
                        <div className="pros-point-no">01</div>
                        <p>
                            Rule based... Do exactly what I want
                        </p>
                    </div>
                </li>
                <li className='pros-point-second pros-point'>
                    <div className='pros-point-content'>
                        <div className="pros-point-no">02</div>
                        <p>
                            Zero Emotions... This is the enemy i want to kill first
                        </p>
                    </div>
                </li>
                <li className='pros-point-third pros-point'>
                    <div className='pros-point-content'>
                        <div className="pros-point-no">03</div>
                        <p>
                            Brokerage savings... create your own system and save commissions
                            </p>
                        </div>
                </li>
                <li className='pros-point-fourth pros-point'>
                    <div className='pros-point-content'>
                        <div className="pros-point-no">04</div>
                        <p>
                            Everyone talks about AI... someday I will also do it
                        </p>
                    </div>
                </li>
                <li className='pros-point-fifth pros-point'>
                    <div className='pros-point-content'>
                        <div className="pros-point-no">05</div>
                        <p>
                            It works even when you are not working
                        </p>
                    </div>
                </li>
            </div>
        </div>
        <div className="featured-banner">
            <div className="featured-banner-content">
                "A custom algo can be deployed at absolute zero infrastucture cost"
            </div>
        </div>
    </div>
  )
}

export default AboutUs