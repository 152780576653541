import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/dist';
import './MenuStyle.css';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    // Cleanup on component unmount
    return () => document.body.classList.remove('no-scroll');
  }, [isMenuOpen]);



  const handleLinkClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <div className={`menu-bar ${isMenuOpen ? 'open' : ''}`}>
      <div className="menu-header">
        <div className="logo">
          <div className="logo-main">
            FIN-INSIGHTS
          </div>
          <div className="logo-sub">
            It starts from YOU
          </div>
        </div>
        <button className="menu-button" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      <div className={`menu-fullscreen ${isMenuOpen ? 'show' : ''}`}>
        <button className="close-button" onClick={toggleMenu}>X</button>
        <ul className="menu-options-list">
          <Link to='/' onClick={handleLinkClick}><li>Home</li></Link>
          <Link to='/services' onClick={handleLinkClick}><li>Services</li></Link>
          <Link to='/blogs' onClick={handleLinkClick}><li>Blogs</li></Link>
          <Link to='/about-us' onClick={handleLinkClick}><li>About Us</li></Link>
          <Link to='/contact-us' onClick={handleLinkClick}><li>Contact</li></Link>
          <Link to='/login' onClick={handleLinkClick}><li>login</li></Link>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
